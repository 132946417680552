import React from "react";
import github from "../images/github-svgrepo-com.svg";
import heroku from "../images/heroku-svgrepo-com.svg";
import vercel from "../images/vercel-svgrepo-com.svg";
import netlify from "../images/netlify-svgrepo-com.svg";
import drIcon from "../images/DR-pics/DR-favicon.png";
import prayOn from "../images/prayon-logo.jpg";
import cmIcon from "../images/ClickMaster-logo.jpg";

let icon1;
let icon2;

const ShowPortfolio = ({ pfSelection, filter }) => {
  return (
    <div className="ShowPortfolio">
      {
        pfSelection.map( item => {
          switch (item.icon1) {
            case 'Github':
              icon1 = github;
              break;
            case 'Heroku':
              icon1 = heroku;
              break;
            case 'Vercel':
              icon1 = vercel;
              break;
            case 'Netlify':
              icon1 = netlify;
              break;
            case 'DR':
              icon1 = drIcon;
              break;
            case 'ClickMaster':
              icon1 = cmIcon;
              break;
            case 'PrayOn':
              icon1 = prayOn;
              break;
            default:
              icon1 = null;
          }
          switch (item.icon2) {
            case 'Github':
              icon2 = github;
              break;
            case 'Heroku':
              icon2 = heroku;
              break;
            default:
              icon2 = null;
          }

          return (
            <div className="portfolio-item" key={item.id}>
              <div className="image-data">
                <img src={item.image} alt ="" />
                <ul className="hover-items">
                  <li>
                    {icon1 && 
                      <a href={item.appUrl} target="_blank" rel="noreferrer">
                        See app 
                        <img src={icon1} alt="" />
                    </a>}
                    {icon2 && 
                      <a href={item.codeUrl} target="_blank" rel="noreferrer">
                        See code  
                        <img src={icon2} alt="" />
                    </a>}
                  </li>
                </ul>
              </div>
              <a href={item.appUrl} target="_blank" rel="noreferrer">{item.title}</a>
              <p className="date">{item.date}</p>
              <p className="text">{item.text}</p>
            </div>
          )
        })
      }
    </div>
  )
}

export default ShowPortfolio;