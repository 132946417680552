import React from "react";
import about from '../images/DR-pics/DR-at-work.jpg'
import dkrCV from '../David-Rhoton-CV-2024.pdf'
import dkrCVNoPhoto from '../David-Rhoton-CV-2024-no-photo.pdf'

const ImageSection = () => {
  return (
    <div className="ImageSection">
      <div className="img">
        <img src={about} alt="office" />
      </div>
      <div className="about-info">
        <h4>I am<span> David Rhoton</span></h4>
        <h3>Full Stack Software Developer</h3>
        <p className="about-text">
          Orginally a marine engineer and an experienced life coach,
          for the last 3 years, I have been focusing on software
          development, especially on JavaScript and the React and React-Native
          frameworks.
        </p>
        <div className="about-details">
          <div className="left-section">
            <p>Full Name</p>
            <p>Age</p>
            <p>Nationality</p>
            <p>Languages</p>
            <p>Current Address</p>
            <p>Countries</p>
          </div>
          <div className="right-section">
            <p>: David Rhoton</p>
            <p>: 58</p>
            <p>: USA / Spain</p>
            <p>: English, German, Spanish, Catalan</p>
            <p>: Castelldefels, Spain</p>
            <p>: USA, Germany, Austria, Spain</p>
          </div>
        </div>
        <div className="cv-btn-section">
          <form
            method="get"
            action="https://drive.google.com/file/d/1S6joeANf_HnMUMCcQ3bbh_2Pz6ybXpIu/view?usp=sharing"
            target="_blank"
          >
            <button className="cv-btn" type="submit">View CV (Resume)</button>
          </form>
          <form method="get" action={dkrCV} target="_blank">
            <button className="cv-btn" type="submit">Download CV (Resume)</button>
          </form>
          <form method="get" action={dkrCVNoPhoto} target="_blank">
            <button className="cv-btn" type="submit">Download w/o photo</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ImageSection;
